import { reactive, ref } from "vue";
import { message } from 'ant-design-vue';
import { delFiles } from "@/api"
import { post } from "@/utils/request"
import { getCurrentTime }  from "@/common/currentTime"

export const files = reactive({data: []});

export const loading = ref(false);

export const getFileCover = (filetype: string, filelink: string) => {
  let fileImg: string;
  switch (filetype) {
    case "txt": fileImg = require("@/assets/fileicon/txt.png");
      break;
    case "pdf": fileImg = require("@/assets/fileicon/pdf.png");
      break;
    case "ppt":
    case "pptx": fileImg = require("@/assets/fileicon/ppt.png");
      break;
    case "xls": fileImg = require("@/assets/fileicon/xls.png");
      break;
    case "doc": 
    case "docx": fileImg = require("@/assets/fileicon/word.png");
      break;
    case "zip":
    case "rar":
    case "7z": fileImg = require("@/assets/fileicon/yasuowenjian.png");
      break;
    case "jpg":
    case "png": 
    case "webp":
    case "bmp": fileImg = filelink;
      break;
    default:
      fileImg = require("@/assets/fileicon/weizhiwenjian.png");
      break;
  }
  return fileImg;
}

export const getFileSize = (filesize: number) => {
  let size: string;
  if (filesize < 1024) {
    size = filesize + "B";
  }
  else if (filesize / 1024 < 1024) {
    size = (filesize / 1024).toFixed(2) + "KB";
  } else {
    size = (filesize / 1024 / 1024).toFixed(2) + "MB";
  }
  return size;
}

const fileList = ref<any>([
      
]);

const complete = ref(0);

const uploadFile = (option: any) => {
  const file = option.file
  const param = new FormData()
  param.append('multipartFile', file)
  const uId = file.uid.split("-")[2] + file.uid.split("-")[3]
  param.append('fileUid', uId)
  param.append('fileSize', option.file.size)
  param.append('fileName', option.file.name.split(".")[0])
  if (option.file.type == "image/jpeg" || option.file.type == "image/png" || option.file.type == "image/webp") {
    param.append('sign', '1')
    if (option.file.size / 1024 / 1024 > 5) {
      return message.error("文件大小不能超过5MB")
    }
  }
  else {
    if (option.file.size / 1024 / 1024 > 300) {
      return message.error("文件大小不能超过300MB")
    }
  }
  post("/files/uploadFiles", param, {
    onUploadProgress: (progressEvent: any) => {
      complete.value = (progressEvent.loaded / progressEvent.total * 100 | 0)
      option.onProgress({ percent: complete.value });
    }
  }).then((res: any) => {
    if (res.code == 500) {
      return message.error("上传失败！");
    }
    option.onSuccess(res, option.file);
    message.success("上传成功!");
    const filesize = getFileSize(option.file.size);
    const filetype = option.file.type.split("/")[1];
    (files.data as any).unshift({
      id: res.data.id,
      filelink: res.data.url,
      filename: option.file.name.split(".")[0],
      filesize: filesize,
      filetype: filetype,
      fileuid: uId,
      filecover: getFileCover(filetype, res.data.url),
      time: getCurrentTime()
    })
  })
}

// 删除文件
const rmFile = (file: any) => {
  const filetype = file.name.split(".")[1];
  const uId = file.uid.split("-")[2] + file.uid.split("-")[3];
  let index: number;
  loading.value = true;
  if (filetype == "jpg" || filetype == "png"  || filetype == "webp") {
    delFiles({file: uId + '.' + filetype, sign: 1}).then((res: any) => {
      loading.value = false;
      if (res.code == 200) {
        for (let i = 0; i < files.data.length; i++) {
          if ((files as any).data[i].fileuid == uId) {
            index = i;
            break;
          }
        }
        (files.data as any).splice(index, 1);
        return message.success("删除成功！");
      }
      message.error("删除失败！");
    })
    return;
  } 
  delFiles({file: uId + '.' + filetype}).then((res: any) => {
    loading.value = false;
    if (res.code == 200) {
      return message.success("删除成功！");
    }
    message.error("删除失败！");
  })
}

export const file = {
  fileList,
  uploadFile,
  rmFile
}