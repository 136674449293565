
import { createVNode, defineComponent, onMounted, reactive, ref } from "vue";
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons-vue"
import { getFiles, delFiles } from "@/api"
import { message, Modal } from "ant-design-vue";
import { file, files, getFileCover, getFileSize, loading } from "./file"

export default defineComponent({
  components: {
    CopyOutlined,
    DeleteOutlined,
    UploadOutlined
  },
  setup() {
    const columns = reactive([
      { title: 'ID', width: 80, dataIndex: 'id', key: 'id' },
      { title: '文件展示', width: 120, key: 'filename', slots: { customRender: 'showFile' } },
      { title: '文件名称', width: 230, key: 'filename', slots: { customRender: 'fileName' } },
      { title: '文件链接', width: 430, dataIndex: 'filelink', key: 'filelink' },
      { title: '文件大小', width: 120, dataIndex: 'filesize', key: 'filesize' },
      { title: '创建时间', dataIndex: 'time', key: 'time' },
      {
        title: '操作',
        key: 'operation',
        width: 160,
        slots: { customRender: 'action' },
      },
    ]);
    // 获取文件参数
    const fileParams = {
      pagenum: 1,
      pagesize: 30
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 30,
      pagesize: fileParams.pagesize
    })
    // 获取文件列表
    const getFilesList = () => {
      loading.value = true;
      getFiles(fileParams).then((res: any) => {
        loading.value = false;
        pagination.value.total = res.data.data.total;
        files.data = res.data.data.files_list.map((v: any) => {
          v.filesize = getFileSize(v.filesize);
          v.filecover = getFileCover(v.filetype, v.filelink);
          return v;
        });
        window.scrollTo(0, 0);
      })
    }
    // 分页
    const changePage = (page: number) => {
      fileParams.pagenum = page;
      getFilesList();
    }
    // 打开文件
    const openFile = (link: string) => {
      window.open(link)
    }
    // 删除
    const isDelFile = (file: any, index: number) => {
      const fileParams = file.fileuid + "." + file.filetype;
      Modal.confirm({
        title: '是否删除该文件',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该操作不可恢复，请谨慎执行！',
        cancelText: "取消",
        okText: "确定",
        onOk() {
          loading.value = true;
          if (file.filetype == "jpg" || file.filetype == "png"  || file.filetype == "webp") {
            delFiles({file: fileParams, sign: 1}).then((res: any) => {
              loading.value = false;
              if (res.code == 500) {
                return message.error("删除失败");
              }
              message.success("删除成功");
              (files as any).data.splice(index, 1);
            })
          } else {
            delFiles({file: fileParams}).then((res: any) => {
              loading.value = false;
              if (res.code == 500) {
                return message.error("删除失败");
              }
              message.success("删除成功");
              (files as any).data.splice(index, 1);
            })
          }
        }
      });
    }
    onMounted(() => {
      loading.value = true;
      getFilesList();
    })
    return {
      ...file,
      loading,
      columns,
      files,
      pagination,
      openFile,
      isDelFile,
      changePage
    }
  }
})
